import React from 'react';


const Mainpanel = (props) => {

    return (
        <div class="pcoded-main-container">
            {props.children}
        </div>
    );
}

export default Mainpanel;