import * as actionTypes from './actions';

const initialState = {
    totalprice : 5,
    DataDownload : true,
    dtdc_lessthan_3 : '0',
    dtdc_greaterthan_3 : '0',
    PaymentTransactions : []
}

const reducer = (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.ADD_INGREDIENT : 
            return {
                ...state,
                totalprice : state.totalprice+1,
            }
        case actionTypes.DOWNLOAD_DATA : 
            return {
                ...state,
                DataDownload : false
            } 
        case actionTypes.UPDATE_PAYMENT_TRANSACTIONS : 
            return {
                ...state,
                PaymentTransactions : action.payload
            }     
        case actionTypes.UPDATE_DTDC_TRACKING_NUMBER_COUNT : 
            return {
                ...state,
                dtdc_lessthan_3 : action.payload,
                dtdc_greaterthan_3 : action.payload_1
            }
        default : 
            return state;
    }
};

export default reducer;