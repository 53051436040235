import React from 'react';
import Aux from '../HOC/Aux';
import {AmplifySignOut } from '@aws-amplify/ui-react';

const Header = (props) => {

    return (
        <Aux>
			   {/*<!-- [ Header ] start -->*/}
		<header class="navbar pcoded-header navbar-expand-lg navbar-light headerpos-fixed header-blue">
			
				
					<div class="m-header">
						<a class="mobile-menu" id="mobile-collapse" onClick={() => this.Change_menu_handler()}><span ></span></a>
						<a href="#!" class="b-brand" >
							{/*<!-- =========   change your logo hear   ============ -->*/}
							
							<img src="assets/images/logo.png" alt="" class="logo"/>
							<img src="assets/images/logo-icon.png" alt="" class="logo-thumb"/>
						</a>
						
							<a href="#!" class="mob-toggler" onClick={() => this.Change_menu_handler()}><i class="feather icon-more-vertical"></i></a>
							
						
					</div>
					
					<div class="collapse navbar-collapse">
						<ul class="navbar-nav mr-auto">
							<li class="nav-item">
								<a href="#!" class="pop-search"><i class="feather icon-search"></i></a>
								<div class="search-bar">
									<input type="text" class="form-control border-0 shadow-none" placeholder="Search here"/>
									<button type="button" class="close" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</li>
							<li class="nav-item">
								<a href="#!" class="full-screen" onclick="javascript:toggleFullScreen()"><i class="feather icon-maximize"></i></a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<li>
								<div class="dropdown">
									<a class="dropdown-toggle" href="#" data-toggle="dropdown"><i class="icon feather icon-bell"></i><span class="badge bg-danger"><span class="sr-only"></span></span></a>
									<div class="dropdown-menu dropdown-menu-right notification">
										<div class="noti-head">
											<h6 class="d-inline-block m-b-0">Notifications</h6>
											<div class="float-right">
												<a href="#!" class="m-r-10">mark as read</a>
												<a href="#!">clear all</a>
											</div>
										</div>
										<ul class="noti-body">
											<li class="n-title">
												<p class="m-b-0">NEW</p>
											</li>
											<li class="notification">
												<div class="media">
													<img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image"/>
													<div class="media-body">
														<p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>5 min</span></p>
														<p>New ticket Added</p>
													</div>
												</div>
											</li>
											<li class="n-title">
												<p class="m-b-0">EARLIER</p>
											</li>
											<li class="notification">
												<div class="media">
													<img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image"/>
													<div class="media-body">
														<p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>10 min</span></p>
														<p>Prchace New Theme and make payment</p>
													</div>
												</div>
											</li>
											<li class="notification">
												<div class="media">
													<img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image"/>
													<div class="media-body">
														<p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>12 min</span></p>
														<p>currently login</p>
													</div>
												</div>
											</li>
											<li class="notification">
												<div class="media">
													<img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image"/>
													<div class="media-body">
														<p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
														<p>Prchace New Theme and make payment</p>
													</div>
												</div>
											</li>
										</ul>
										<div class="noti-footer">
											<a href="#!">show all</a>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="dropdown">
									{/*<a href="#!" class="displayChatbox dropdown-toggle"><i class="icon feather icon-mail"></i><span class="badge bg-success">hi<span class="sr-only"></span></span></a>*/}
									<span><AmplifySignOut/></span>	
								</div>
							</li>
							<li>
								<div class="dropdown drp-user">
									<a href="#!" class="dropdown-toggle" data-toggle="dropdown">
										<img src="assets/images/user/avatar-1.jpg" class="img-radius wid-40" alt="User-Profile-Image"/>
									</a>
									{/*<div class="dropdown-menu dropdown-menu-right profile-notification">
										<div class="pro-head">
											<img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image"/>
											<span>John Doe</span>
											<a href="auth-signin.html" class="dud-logout" title="Logout">
												<i class="feather icon-log-out"></i>
											</a>
										</div>
										<ul class="pro-body">
											<li><a href="user-profile.html" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
											<li><a href="email_inbox.html" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>
											<li><i class="feather icon-lock"></i><AmplifySignOut/></li>
										</ul>
									</div>*/}
								</div>
							</li>
						</ul>
					</div>
					
				
		</header>
		{/*<!--- [ Header ] end -->*/}
			</Aux>

    )
}

export default Header;