import React,{Component} from 'react';
import Aux from '../HOC/Aux';
import { Container,Button,CardDeck,CardGroup,Card,Row,Col,Table} from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import { Link } from 'react-router-dom';


class Main_Content extends Component  {

    state = {
        dtdc_lessthan_3 : '0',
        dtdc_greaterthan_3 : '',
        TotalRechargeCount : '0'
    }


    DownloadCustomerDatahandler = async() => {

        console.log("I am going to fetch all the data please wait")

        let payload = {};

        payload.operation = 'payment_info'

        let server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/analytics',JSON.stringify(payload));
        console.log("Response analystics = "+JSON.stringify(server_response.data));

        let PaymentTransactions = server_response.data.Items; 

        console.log("Payment array ="+JSON.stringify(PaymentTransactions))

        payload.operation = 'dtdc_info'

        server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/analytics',JSON.stringify(payload));
        console.log("Response analystics = "+JSON.stringify(server_response.data)); 

        let temp_array = [];

        temp_array =  server_response.data.Items[0].Tracking_Number 
        console.log("Array Length 1 ="+temp_array.length);

        let dtdc_above3kg = temp_array.length 

        temp_array =  server_response.data.Items[1].Tracking_Number 
        console.log("Array Length 2 ="+temp_array.length);

        let dtdc_less3kg = temp_array.length 

        this.setState({
            dtdc_lessthan_3 :dtdc_less3kg,
            dtdc_greaterthan_3 : dtdc_above3kg,
            TotalRechargeCount : PaymentTransactions.length 
        })

        this.props.updatepaymentall(PaymentTransactions);
        this.props.updatedtdc_count(dtdc_less3kg,dtdc_above3kg);
        this.props.checkdownload()

    }

    componentDidMount(){
        
        try {

            if(this.props.DataDownload === true){
            
                console.log("Checking if needed to download ="+this.props.DataDownload)
                this.DownloadCustomerDatahandler(); 

            }
            else {
                console.log("No download as because ="+this.props.DataDownload)
            }


            //let server_response = await axios.get('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/hello')
            //console.log("Response = "+JSON.stringify(server_response.data));

            //let temp_array = [];

            //temp_array =  server_response.data.Tracking_Number
            //console.log("Array Length ="+server_response.data.Tracking_Number.length);

            /*server_response.data.map((row) => {
                if(row.Type == 'kerala_below3kg') {
                    this.setState({
                        ...this.state,
                        dtdc_lessthan_3 : row.Tracking_Number.length
                    }) 
                }
                if(row.Type == 'kerala_above3kg') {
                    this.setState({
                        ...this.state,
                        dtdc_greaterthan_3 : row.Tracking_Number.length
                    }) 
                } 
                console.log(" I am counting");
            })
            */
            /*
            this.setState({
                ...this.state,
                dtdc_lessthan_3 : server_response.data.Tracking_Number.length
            })
            */    
        }
        catch(err) {
            console.log("Some Error = "+err)
        }
        
    }

    render (){

        //let DownloadStatus = this.props.DataDownload
        let DownloadStatus = `${this.props.DataDownload}`


        return (
            <Aux>
                {/*<!-- [ Main Content ] start -->*/}
            {/*<div class="pcoded-main-container">*/}
                <div class="pcoded-content"></div>
                {/*<!-- [ breadcrumb ] start -->*/}
                <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-b-10">Dashboard Analytics : {DownloadStatus}</h5>
                            </div>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                                <li class="breadcrumb-item"><a href="#!">Dashboard Analytics</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->*/}
                <div class="row">
                {/*<!-- order-card start -->*/}
                <div class="col-md-6 col-xl-3">
                    <div class="card bg-c-blue order-card">
                        <div class="card-body">
                            <h6 class="text-white">DTDC Available Tracking Number</h6>
                            <h2 class="text-right text-white"><i class="feather icon-shopping-cart float-left"></i><span>{this.state.dtdc_lessthan_3}</span></h2>
                            <p class="m-b-0">*Less than 3KG<span class="float-right">..</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3">
                    <div class="card bg-c-green order-card">
                        <div class="card-body">
                            <h6 class="text-white">Recharge Done</h6>
                            <h2 class="text-right text-white"><i class="feather icon-tag float-left"></i><span>INR {this.state.dtdc_greaterthan_3}23</span></h2>
                            <p class="m-b-0"><span class="float-left">
                            <select name="cars" class="form-control">
                                    <option value="volvo">Today</option>
                                    <option value="volvo">This Week</option>
                                    <option value="volvo">Current Month</option>
                                    <option value="saab">Previous Month</option>
                                    <option value="mercedes">Current Year</option>
                                    <option value="audi">Previous Year</option>
                                    <option value="audi">ALL</option>
                                </select>
                                </span> 
                                <span class="float-right">...</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3">
                    <div class="card bg-c-yellow order-card">
                        <div class="card-body">
                            <h6 class="text-white">Total Bookings</h6>
                            <h2 class="text-right text-white"><i class="feather icon-repeat float-left"></i><span><Link to="/Payment_list">{this.props.PaymentTransactions.length}</Link></span></h2>
                            <p class="m-b-0"><span class="float-left">
                                <select name="cars" class="form-control">
                                    <option value="volvo">Today</option>
                                    <option value="volvo">This Week</option>
                                    <option value="volvo">Current Month</option>
                                    <option value="saab">Previous Month</option>
                                    <option value="mercedes">Current Year</option>
                                    <option value="audi">Previous Year</option>
                                    <option value="audi">ALL</option>
                                </select>
                                </span>
                                <span class="float-right">$5,032</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3">
                    <div class="card bg-c-red order-card">
                        <div class="card-body">
                            <h6 class="text-white">Total Profit</h6>
                            <h2 class="text-right text-white"><i class="feather icon-award float-left"></i><span>$9,562</span></h2>
                            <p class="m-b-0"><span class="float-left">
                                <select name="cars" class="form-control">
                                    <option value="volvo">Current Month</option>
                                    <option value="saab">Previous Month</option>
                                    <option value="mercedes">Current Year</option>
                                    <option value="audi">Previous Year</option>
                                    <option value="audi">ALL</option>
                                </select>
                                </span> 
                                <span class="float-right">$542</span></p>
                        </div>
                    </div>
                </div>
                {/*<!-- order-card end -->*/}
                {/*<!-- [ basic-table ] start -->*/}
                {/*<div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>Basic Table</h5>
                            <span class="d-block m-t-5">use class <code>table</code> inside table element</span>
                        </div>
                        <div class="card-body table-border-style">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>*/}
                {/*<!-- [ basic-table ] end -->*/}
                
                </div>
                
               
                
      <Row>
            <Col md={3}>
            
                <div class="card prod-p-card bg-c-red">
                    <div class="card-body">
                        <div class="row align-items-center m-b-0">
                            <div class="col">
                                <h6 class="m-b-5 text-white">DTDC Nos Less 3KG</h6>
                                <h3 class="m-b-0 text-white">{this.props.dtdc_lessthan_3}</h3>
                            </div>
                            <div class="col-auto">
                                <i class="fas fas fa-venus text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            
            </Col>
            <Col md={3}>
            
                <div class="card prod-p-card bg-c-red">
                    <div class="card-body">
                        <div class="row align-items-center m-b-0">
                            <div class="col">
                                <h6 class="m-b-5 text-white">DTDC Nos Above 3KG</h6>
                                <h3 class="m-b-0 text-white">{this.props.dtdc_greaterthan_3}</h3>
                            </div>
                            <div class="col-auto">
                                <i class="fas fas fa-venus text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            
            </Col>

            <Col md={3}>
            
                <div class="card prod-p-card bg-c-red">
                    <div class="card-body">
                        <div class="row align-items-center m-b-0">
                            <div class="col">
                                <h6 class="m-b-5 text-white">Total users</h6>
                                <h3 class="m-b-0 text-white">1</h3>
                            </div>
                            <div class="col-auto">
                                <i class="fas fas fa-user-friends text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            
            </Col>

            <Col md={3}>
            
                <div class="card prod-p-card bg-c-red">
                    <div class="card-body">
                        <div class="row align-items-center m-b-0">
                            <div class="col">
                                <h6 class="m-b-5 text-white">Today Recharge</h6>
                                <h3 class="m-b-0 text-white">1</h3>
                            </div>
                            <div class="col-auto">
                                <i class="fas fas fa-user-friends text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            
            </Col>

            
      </Row>
      <Row>
        <Col md={3}>
                
                <div class="card prod-p-card bg-c-red">
                    <div class="card-body">
                        <div class="row align-items-center m-b-0">
                            <div class="col">
                                <h6 class="m-b-5 text-white">Customers With Low Balance</h6>
                                <h3 class="m-b-0 text-white">1</h3>
                            </div>
                            <div class="col-auto">
                                <i class="fas fas fa-user-friends text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            
            </Col>
      </Row>
    
                
      
            {/*</div>*/}
            
            </Aux>
        )

    }

    
}

const mapStateToprops = state => {
    return {
        price : state.totalprice,
        DataDownload : state.DataDownload,
        dtdc_lessthan_3 : state.dtdc_lessthan_3,
        dtdc_greaterthan_3 : state.dtdc_greaterthan_3,
        PaymentTransactions : state.PaymentTransactions
    };
}

const mapDispatchToProps = dispatch => {
    return {
        checkdownload : () => dispatch({ type: actionTypes.DOWNLOAD_DATA} ),
        updatepaymentall : (data) => dispatch({ type: actionTypes.UPDATE_PAYMENT_TRANSACTIONS,payload:data}),
        updatedtdc_count : (lessthan3,above3) => dispatch({ type: actionTypes.UPDATE_DTDC_TRACKING_NUMBER_COUNT,payload:lessthan3, payload_1:above3})
    }
}

export default connect(mapStateToprops,mapDispatchToProps)(Main_Content);