import React,{Component} from 'react';
import Aux from '../../../HOC/Aux';
import { Container,Button,CardDeck,CardGroup,Card,Row,Col,Table} from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
//import * as actionTypes from '../../../store/actions';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $
$.DataTable = require('datatables.net');


class AllPaymentList extends Component  {

    componentDidMount() {


        $('#example').DataTable({
            //paging: false,
            ///searching: false
        });   

    }

    render (){

        let PaymentTransactions = this.props.PaymentTransactions;

        return(
            <Aux>
                <Helmet>
                <link rel="stylesheet" href="https://cdn.datatables.net/1.10.23/css/jquery.dataTables.min.css" />
                </Helmet>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>

                            </Card.Header>
                            <Card.Body>
                                
                        <table id="example" class="table  table-bordered nowrap hover table-xl">
        <thead>
            <tr>
                <th>NO</th>
                <th>Enquiry NO</th>
                <th>CUSTOMER NAME</th>
                <th>Title</th>
                <th>Enquiry Date</th>
                <th>Contact Person</th>
                
            </tr>
        </thead>
        <tbody>
            
                
            {PaymentTransactions.map((row,index) => {
                return (
                    <tr key={index}>
                    <td>{row.Payment_ID}</td>
                    <td>{row.Order_ID}</td>
                    <td>{row.Email}</td>
                    <td>{row.Date}</td>
                    <td>{row.Amount}</td>
                    <td>{row.Signature}</td>
                </tr> 
                )
            })}
                                        
                    
           
        </tbody>
        <tfoot>
            <tr>
                <th>NO</th>
                <th>Enquiry NO</th>
                <th>CUSTOMER NAME</th>
                <th>Title</th>
                <th>Enquiry Date</th>
                <th>Contact Person</th>
                
            </tr>
        </tfoot>
    </table>
                        
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                
            </Aux>
               
        
        )
    }

}

const mapStateToprops = state => {
    return {
        PaymentTransactions : state.PaymentTransactions
    };
}

export default connect(mapStateToprops)(AllPaymentList);