import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import axios from 'axios';
import { Container,Button,CardDeck,CardGroup,Card,Row,Col,Table} from 'react-bootstrap';
import DTDC_row_tracking from './DTDC_row_tracking';


class DTDC_Tracking extends Component {

    state = {
        dtdc_tracking_row_count : 0,
        dtdc_tracking_numbers : [],
        dtdc_weight_default_category : 'Less than 3KG'
    }

    dtdc_tracking_handler = (event,category,index) => {
        console.log(category+"Got the hit"+event.target.value);

        if(category === 'weight_info'){
            if(this.state.dtdc_weight_default_category =='Less than 3KG') {
                this.setState({
                    dtdc_weight_default_category : 'Greater than 3KG'
                });
            }
            else {
                this.setState({
                    dtdc_weight_default_category : 'Less than 3KG'
                }); 
            }
        }
        if(category === 'row_count'){
            //console.log("getting hitt"+event.target.value)
            //const row_count = event.target.value
            this.setState({
                dtdc_tracking_row_count : event.target.value
            })
            
        }

        if(category === 'tracking_submit') {
            console.log("got the submit requestst");

            let type ='';

           if(this.state.dtdc_weight_default_category === 'Less than 3KG') {
                type = 'kerala_below3kg';
           }
           else {
                type = 'kerala_above3kg';
           }

            const payload = {
                             tracking_numbers : this.state.dtdc_tracking_numbers,
                             type: type,
                             company : 'DTDC'
                         }
            
            axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/adddtdcrate',JSON.stringify(payload))
                    .then(res => {
                        //console.log(res.config.data);
                        console.log(res.data);
                        this.setState({
                            ...this.state,
                            dtdc_tracking_numbers : []
                        })
                        
                        
                    })
                    .catch(error => {
                        console.log("some error happened");
                    });             
        }

        if(category === 'tracking_input') {
            console.log("gocha"+index);

            let temp_array = [...this.state.dtdc_tracking_numbers]
            let temp = event.target.value;
            let newtmp= temp.toString()

            if(newtmp.length ==9){
                //temp_array.splice(index,0, temp);
                temp_array.push(temp);
                 
                this.setState({
                    ...this.state,
                    dtdc_tracking_numbers : temp_array
                    });
                    console.log("legnt = "+newtmp.length)
                }
                 
                 
            if(newtmp.length <9){
                //tempstate.trackingnumber.splice(index,1);
                temp_array.splice(index,1)

                this.setState({
                    ...this.state,
                    dtdc_tracking_numbers : temp_array
                    })
                    //console.log("legnt = "+newtmp.length)
    
            }
        }


        
        
    }



    render () {

        var dtdc_row_array = [];
        var array_count = this.state.dtdc_tracking_row_count; 

        while(dtdc_row_array.length < array_count) {
            dtdc_row_array.push("")
        }
        


        return(
            <Aux>
                <h1> Hello</h1>
                <Row>
                    <Col>
                    <div class="card">
                    <div class="card-header">
                        <h5>DTDC Tracking Entry</h5>
                        <br/>
                        <br/>
                        <div class="form-inline">
                            
                            <div class="form-group mx-sm-3 mb-2">
                                <input type="text" class="form-control" id="inputPassword2" placeholder="Enter the No of Rows" onChange={(e) => this.dtdc_tracking_handler(e,'row_count')}/>
                            </div>
                            
                            
                            <div class="form-group mx-sm-3 mb-2">
                                <div class="switch switch-info d-inline m-r-10">
                                    <input type="checkbox" id="switch-i-1" onChange={(e) => this.dtdc_tracking_handler(e,'weight_info')}/>
                                    <label for="switch-i-1" class="cr"></label>
                                </div>
                                <label>Above 3KG</label>
                            </div>

                            <button onClick={(e) => this.dtdc_tracking_handler(e,'tracking_submit')} class="btn  btn-primary mb-2" >Submit Tracking Numbers</button>
                        </div>
                    </div>
                    <div class="card-body">
                        
                        <div class="dt-responsive table-responsive">
                            <table id="form-input-table" class="table table-striped table-bordered nowrap">
                                <thead>
                                    <tr>
                                        <th>DTDC</th>
                                        <th>Tracking Number</th>
                                        <th>Weight Category</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {dtdc_row_array.map((row,index) => {
                                    return(
                                        <DTDC_row_tracking
                                            row_number = {index+1}
                                            dtdc_tracking_handler = {(e,type,index) => this.dtdc_tracking_handler(e,type,index)}
                                            weight_category = {this.state.dtdc_weight_default_category} 
                                      />     
                                    )
                                           
                                    })}
                                    
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>DTDC</th>
                                        <th>Tracking Number</th>
                                        <th>Weight Category</th>
                                        
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                    </Col>
                </Row>
            </Aux>
        )
    }
}

export default DTDC_Tracking;