import React from 'react'; 
import AUX from '../../../HOC/Aux';

const DTDC_row_tracking = (props) => {

    return (
        <AUX>
            <tr>
                <td>{props.row_number}</td>
                <td>
                    <input type="text" maxLength="9" class="form-control" id="row-1-age" name="row-1-age" onChange={(e) => props.dtdc_tracking_handler(e,'tracking_input',props.row_number-1)}/>
                </td>
                <td>
                    <input type="text" class="form-control" id="row-1-position" name="row-1-position" value={props.weight_category}/>
                </td>
                                        
            </tr>
        </AUX>
    )
}

export default DTDC_row_tracking;