import React, { Component } from 'react';
import {Route, Link, Switch} from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Container,Button,CardDeck,CardGroup,Card,Row,Col,Table} from 'react-bootstrap';
import Aux from './HOC/Aux';
import $ from 'jquery';
import axios from 'axios'


import logo from './logo.svg';
import './App.css';

import Header from './Pages/Header';
import Sidebar from './Pages/Sidebar';
import Main_Content from './Pages/Main_Content';
import DTDC_Tracking from './Pages/AWS_Modules/DTDC/DTDC_Tracking';
import Mainpanel from './components/Wrapper/Mainpanel';
import AllPaymentList from './Pages/AWS_Modules/PAYMENT/AllPaymentList';

Amplify.configure(awsconfig);

class App extends Component {


  render (){


    return (
      <Aux>

        <Sidebar/>
        <Header/>
        {/*<div class="pcoded-main-container">
            <Main_Content/>
    </div>*/}
    
        <Mainpanel>
            {/*<Main_Content/>*/}
            {/*<DTDC_Tracking/>*/}
            <Switch>
                <Route path="/" exact component={Main_Content}/>
                <Route path="/dtdc_test" exact component={DTDC_Tracking}/>
                <Route path="/Payment_list" exact component={AllPaymentList}/>
            </Switch>
        </Mainpanel>
        
        
  


      </Aux>
      
    );
  }
  
}

export default withAuthenticator(App);
