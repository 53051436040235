/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:a0fedb55-416e-4b6d-9fbc-b256c4978228",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_pLWi5ckke",
    "aws_user_pools_web_client_id": "1tp66ovqkutb28khuop5kfvp7e",
    "oauth": {}
};


export default awsmobile;
